<template>
  <v-container fluid class="flex-container-overflow">
    <ValidationObserver ref="obs" slim v-slot="{ handleSubmit }">
      <v-form class="row justify-center align-end" style="height: 100%; min-height: 70vh;"
              @submit.prevent="handleSubmit(loginUser)">
        <v-col cols="12">
          <div class="text-center text--secondary">
            {{ $t('Login.welcome') }}
          </div>
        </v-col>
        <v-col
          sm="12"
          cols="10"
        >
          <v-col cols="12">
            <div class="title text-center">
              {{ $t('Login.login') }}
            </div>
          </v-col>
          <v-col v-for="(field, index) in loginFields"
                 :key="field.name" cols="12" sm="6" class="mx-auto pa-0">
            <TextFieldWithValidation
              v-model="field.model"
              :name="field.name"
              :autocomplete="field.autocomplete"
              outlined
              dense
              mode="lazy"
              :label="field.label"
              :rules="field.rules"
              :type="field.type"
            />
            <v-col v-if="index === loginFields.length - 1" cols="12" sm="6" class="pa-0">
              <router-link to="/forgotten-password"
                           style="color: #3aa8cc; text-decoration: none;"
                           class="text-uppercase caption font-weight-bold"
              >{{ $t('Login.forgottenPassword') }}</router-link>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="8" sm="12" class="pt-0">
          <v-col v-if="signupError"  cols="12" sm="6" lg="3" class="mx-auto py-0 text-center">
            <div class="error--text">
              Error signing in with Azure
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="mx-auto">
            <v-btn rounded color="blue" dark block :loading="azureLoading"
                   @click="azureSignIn"
            >{{ $t('Login.azure')}}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="mx-auto">
            <v-btn rounded color="primary" block type="submit" :loading="loading"
            >{{ $t('Login.login') }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="mx-auto">
            <v-btn rounded color="accent" block to="register"
            >{{ $t('Login.register') }}</v-btn
            >
          </v-col>
        </v-col>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'Login',
  components: {
    ValidationObserver
  },
  data() {
    return {
      loginFields: [
        {
          name: 'username',
          autocomplete: '',
          model: '',
          rules: 'required|email',
          label: this.$t('inputLabels.email'),
          type: 'email',
          class: ''
        },
        {
          name: 'password',
          autocomplete: 'current-password',
          model: '',
          rules: 'required|min:8',
          label: this.$t('inputLabels.password'),
          type: 'password',
          class: 'py-0'
        }
      ],
      azureLoading: false,
      loading: false,
      account: null,
      signupError: ''
    };
  },
  methods: {
    async loginUser() {
      try {
        this.loading = true;
        const payload = {};
        this.loginFields.forEach((field) => {
          payload[field.name] = field.model
        });
        const response = await this.$store.dispatch('user/login', payload);
        if (response.status === 203) {
          this.$router.push('/set-password');
        } else {
          this.$router.push('/');
        }
      } catch (err) {
        if (err && err.response.data.detail) {
          this.$refs.obs.setErrors({
            username: err.response.data.detail,
            password: err.response.data.detail
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async azureSignIn() {
      try {
        this.azureLoading = true;
        await this.$store.dispatch('user/getAzureAccessToken');
        await this.$router.push('/');
      } catch (e) {
        this.signupError = e;
      } finally {
        this.azureLoading = false;
      }
    },
  }
};
</script>

<style scoped>

</style>
